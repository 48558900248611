var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"poll-results"},_vm._l((_vm.poll.question.variants),function(variant){return _c('div',{key:variant.id,staticClass:"poll-results__item item"},[_c('p',{staticClass:"item__text"},[_vm._v(_vm._s(variant.text))]),_c('div',{staticClass:"item__result"},[_c('div',{staticClass:"item__line"},[_c('div',{staticClass:"item__inner-line",class:{
            'item__inner-line_correct': variant.is_correct,
            'item__inner-line_uncorrect':
              !variant.is_correct &&
              _vm.poll.answers.find(
                (item) => item.test_question_variant_id === variant.id
              ),
          }},[(
              _vm.poll.answers.find(
                (item) => item.test_question_variant_id === variant.id
              )
            )?_c('icon-base',{attrs:{"icon-name":"icon-poll-chosen","icon-fill":"#2f2f2f","height":"10","width":"10","view-box-width":"10","view-box-height":"10"}},[_c('icon-poll-chosen')],1):_vm._e()],1)]),(
          _vm.poll.answers.find(
            (item) => item.test_question_variant_id === variant.id
          )
        )?_c('p',{staticClass:"item__number"},[_vm._v(" Ваш выбор ")]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }