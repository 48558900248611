<script>
import IconBase from "@/components/helpers/IconBase";
import IconPollChosen from "@/components/icons/IconPollChosen";

export default {
  name: "TestResult",

  components: { IconPollChosen, IconBase },

  props: {
    poll: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="poll-results">
    <div
      v-for="variant in poll.question.variants"
      :key="variant.id"
      class="poll-results__item item"
    >
      <p class="item__text">{{ variant.text }}</p>

      <div class="item__result">
        <div class="item__line">
          <div
            class="item__inner-line"
            :class="{
              'item__inner-line_correct': variant.is_correct,
              'item__inner-line_uncorrect':
                !variant.is_correct &&
                poll.answers.find(
                  (item) => item.test_question_variant_id === variant.id
                ),
            }"
          >
            <icon-base
              v-if="
                poll.answers.find(
                  (item) => item.test_question_variant_id === variant.id
                )
              "
              icon-name="icon-poll-chosen"
              icon-fill="#2f2f2f"
              height="10"
              width="10"
              view-box-width="10"
              view-box-height="10"
            >
              <icon-poll-chosen />
            </icon-base>
          </div>
        </div>
        <p
          v-if="
            poll.answers.find(
              (item) => item.test_question_variant_id === variant.id
            )
          "
          class="item__number"
        >
          Ваш выбор
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.poll-results {
  &__item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: $space-m;
    }
  }

  .item {
    &__text {
      margin-bottom: $space-s;
    }

    &__result {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 12px;
    }

    &__line {
      background-color: $light-second;
      height: 20px;
      border-radius: 8px;
      overflow: hidden;
      width: 100%;
    }

    &__inner-line {
      background-color: $light-fifth;
      height: 20px;
      width: 100%;
      display: flex;
      align-items: center;

      &_correct {
        background-color: $green;
      }
      &_uncorrect {
        background-color: $red;
      }

      .icon-base {
        margin-left: $space-s;
      }
    }

    &__number {
      @include body-1-bold;
      color: $dark-primary;
    }

    &_text-answer {
      .item__title {
        @include text-2-bold;
        color: $dark-primary;
        margin-bottom: $space-s;
      }

      .item__text {
        @include body-1;
        color: $dark-primary;
        margin-bottom: 0;
      }
    }
  }
}
</style>
