<template>
  <div class="test">
    <button-redirect
      text="Назад к обучению"
      @click="$router.push({ name: 'Study' })"
    />
    <h4>Тест: проверка перед экзаменом</h4>
    <p>
      Чтобы успешно пройти тестирование и получить доступ к экзамену, нужно
      допустить не более 2 ошибок, то есть правильно ответить минимум на 18
      вопросов из 20. Время выполнения не ограниченно.
    </p>
    <div v-if="test && test.status !== 'awaited'" class="test__result">
      <p>
        Результат
        <span>
          верно
          {{ test.fields.filter((question) => question.is_correct).length }}
          из
          {{ test.fields.length }}
        </span>
      </p>
    </div>
    <div class="test__numbers">
      <button
        v-for="(number, index) in test.fields"
        :key="index"
        :class="{
          number_active: index === select_number && test.status === 'awaited',
          number_passed: number.answers.length && test.status === 'awaited',
          number_success: number.is_correct && test.status !== 'awaited',
          number_failed: !number.is_correct && test.status !== 'awaited',
        }"
        @click="goToPoll(index)"
      >
        {{ index + 1 }}
      </button>
    </div>
    <div v-if="test.status === 'not_passed'" class="test__replay">
      <main-button
        title="Пройти еще раз"
        color="dark"
        padding="14px 24px"
        @click="replayTest"
      />
    </div>
    <template v-if="test">
      <div v-if="test.status === 'awaited'" class="test__question">
        <test-item
          :test-status="test.status"
          :poll="test.fields[select_number]"
          :select-number="select_number"
          :fields="test.fields"
          @nextQuestion="updateTest"
          @finishTest="finishTest"
        />
      </div>
      <div v-else class="test__results">
        <test-item
          v-for="(poll, index) in test.fields"
          :id="[`poll-${index}`]"
          :key="poll.id"
          :poll="poll"
          :select-number="0"
          :fields="[]"
        />
      </div>
    </template>
    <template v-if="test && test.status !== 'awaited'">
      <div class="test__result">
        <p>
          Результат
          <span>
            верно
            {{ test.fields.filter((question) => question.is_correct).length }}
            из
            {{ test.fields.length }}
          </span>
        </p>
      </div>
      <div class="test__numbers">
        <button
          v-for="(number, index) in test.fields"
          :key="index"
          :class="{
            number_active: index === select_number && test.status === 'awaited',
            number_passed: number.answers.length && test.status === 'awaited',
            number_success: number.is_correct && test.status !== 'awaited',
            number_failed: !number.is_correct && test.status !== 'awaited',
          }"
          @click="goToPoll(index)"
        >
          {{ index + 1 }}
        </button>
      </div>
      <div class="test__replay">
        <main-button
          v-if="test.status === 'not_passed'"
          title="Пройти еще раз"
          color="dark"
          padding="14px 24px"
          @click="replayTest"
        />
        <main-button
          title="Вернуться к обучению"
          color="outlined"
          padding="14px 24px"
          @click="$router.push({ name: 'Study' })"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect";
import MainButton from "@/components/helpers/MainButton";
import TestItem from "@/components/poll/TestItem";
import { mapActions } from "vuex";
export default {
  name: "StudyTest",

  components: { TestItem, MainButton, ButtonRedirect },

  data() {
    return {
      select_number: 0,
      test: "",
    };
  },

  methods: {
    ...mapActions({
      loadTest: "tutorial/loadTest",
      startTest: "tutorial/startTest",
    }),

    updateTest(data) {
      this.test = data;
      this.select_number++;
    },

    finishTest(data) {
      this.test = data;
    },

    replayTest() {
      this.startTest().then(() => {
        this.loadTest()
          .then((response) => {
            this.test = response.data.data;
          })
          .catch(() => {
            this.$router.push({ name: "Study" });
          });
      });
    },

    goToPoll(index) {
      if (this.test.status !== "awaited") {
        document
          .getElementById(`poll-${index}`)
          .scrollIntoView({ block: "start", behavior: "smooth" });
        return;
      }

      this.select_number = index;
    },
  },

  beforeMount() {
    this.loadTest()
      .then((response) => {
        this.test = response.data.data;
        if (this.test.status === "awaited") {
          this.select_number = this.test.fields.findIndex(
            (answer) => answer.answers.length === 0
          );
        }
      })
      .catch(() => {
        this.$router.push({ name: "Study" });
      });
  },
};
</script>

<style scoped lang="scss">
.test {
  padding: $space-xxl;
  @media (max-width: 575px) {
    padding: $space-xl;
  }
  @media (max-width: 424px) {
    padding: $space-l;
  }
  @media (max-width: 374px) {
    padding: $space-m;
  }
  > button {
    margin: 0 0 $space-xs;
  }
  > h4 {
    @include title-3();
    margin: 0 0 $space-m;
    max-width: 580px;
    color: $dark-primary;
  }
  > p {
    max-width: 580px;
    @include body-1();
    margin: 0 0 $space-xl;
    color: $black;
  }
  &__result {
    margin: 0 0 $space-s;
    > p {
      @include body-1-bold();
      color: $dark-primary;
      > span {
        @include body-1();
        color: $dark-fifth;
      }
    }
  }
  &__numbers {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: $space-xs;
    margin: 0 0 $space-l;
    max-width: 580px;
    > button {
      width: 25px;
      height: 25px;
      border-radius: 4px;
      background: $light-fourth;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      @include caption-1();
    }
  }
  &__replay {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 $space-xl;
    grid-gap: $space-s;
    > button {
      width: auto;
    }
  }
  &__question {
    max-width: 720px;
    width: 100%;
  }
  &__results {
    max-width: 720px;
    width: 100%;
  }
}

.number {
  &_active {
    outline: 1px solid $gold-main;
  }
  &_passed {
    background: $gold-main !important;
  }
  &_success {
    background: $green !important;
    cursor: pointer;
  }
  &_failed {
    background: $red !important;
    cursor: pointer;
  }
}
</style>
