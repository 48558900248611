<script>
import { mapActions } from "vuex";
import MainButton from "@/components/helpers/MainButton";
import MainInputRadioGroup from "@/components/helpers/MainInputRadioGroup";
import MainInputCheckboxGroup from "@/components/helpers/MainInputCheckboxGroup";
import TestResult from "@/components/poll/TestResult";

export default {
  components: {
    TestResult,
    MainInputCheckboxGroup,
    MainInputRadioGroup,
    MainButton,
  },

  props: {
    poll: {
      type: Object,
      required: true,
    },
    selectNumber: {
      type: Number,
    },
    fields: {
      type: Array,
    },
    testStatus: {
      type: String,
    },
  },

  data() {
    return {
      selectedOptions: [],
    };
  },

  computed: {
    formattedOptions() {
      return this.poll.question.variants;
    },

    showRadioButtons() {
      return (
        this.poll.question.variants.length > 0 &&
        this.poll.question.type === "single"
      );
    },

    showCheckboxButtons() {
      return (
        this.poll.question.variants.length > 0 &&
        this.poll.question.type === "multiple"
      );
    },

    disabledButton() {
      return !(this.selectedOptions.length > 0);
    },
  },

  watch: {
    poll(newValue) {
      this.selectedOptions = [];

      if (newValue.answers.length) {
        for (let i in this.poll.answers) {
          this.selectedOptions.push(
            this.poll.question.variants.find(
              (question) =>
                question.id === newValue.answers[i].test_question_variant_id
            )
          );
        }
      }
    },
  },

  methods: {
    ...mapActions({
      answerTest: "tutorial/answerTest",
      changeAnswerTest: "tutorial/changeAnswerTest",
      finishTest: "tutorial/finishTest",
    }),

    sendAnswer() {
      const payload = this.setAnswerData();

      if (this.poll.answers) {
        this.changeAnswerTest(payload).then((response) => {
          if (this.selectNumber + 1 === this.fields.length) {
            this.finishTest().then((resp) => {
              this.$emit("finishTest", resp.data.data);
            });
            return;
          }

          this.$emit("nextQuestion", response.data.data);
        });
        return;
      }

      this.answerTest(payload).then((response) => {
        if (this.selectNumber + 1 === this.fields.length) {
          this.finishTest().then((resp) => {
            this.$emit("finishTest", resp.data.data);
          });
          return;
        }

        this.$emit("nextQuestion", response.data.data);
      });
    },

    setAnswerData() {
      let payload = {
        pollId: this.poll.id,
        data: {},
      };

      let array = [];

      this.selectedOptions.forEach((option) => {
        if (option.id !== "custom") {
          array.push(option.id);
        }
      });

      if (array.length > 0) {
        payload.data.variants = array;
      }

      return payload;
    },
  },
};
</script>

<template>
  <div
    class="poll"
    :class="{ poll_failed: testStatus !== 'awaited' && !poll.is_correct }"
  >
    <h5 class="poll__question">{{ poll.question.text }}</h5>

    <p class="poll__description">
      {{
        poll.question.type === "single"
          ? "Выберите один верный ответ"
          : "Выберите наиболее подходящие варианты"
      }}
    </p>

    <template v-if="testStatus === 'awaited'">
      <div class="poll__wrapper">
        <main-input-checkbox-group
          v-if="showCheckboxButtons"
          :selected-options.sync="selectedOptions"
          :options="formattedOptions"
        />

        <main-input-radio-group
          v-if="showRadioButtons"
          v-model="selectedOptions"
          :name="poll.question.text"
          :options="formattedOptions"
        />
      </div>

      <div class="poll__button">
        <main-button
          :title="
            selectNumber + 1 === fields.length ? 'Узнать результаты' : 'Далее'
          "
          color="dark"
          :disabled="disabledButton"
          padding="14px 24px"
          @click="sendAnswer"
        />
      </div>
    </template>

    <template v-else>
      <test-result :poll="poll" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.poll {
  background-color: $light-primary;
  padding: $space-xl;
  border-radius: $space-s;
  max-width: 680px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $space-m;
  }
  &:last-child {
    margin-bottom: $space-xl;
  }

  &_failed {
    outline: 1px solid $red;
  }

  &__question {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-s;
  }

  &__description {
    @include text-2;
    color: $dark-third;
  }

  &__wrapper {
    margin-top: $space-l;

    .main-input {
      margin-top: $space-s;
    }
  }

  &__button {
    margin-top: $space-l;
    > button {
      width: auto;
    }
  }

  &__button-expand {
    margin-top: $space-m;

    &_expanded {
      margin-top: $space-l;
    }
  }

  .poll-results {
    margin-top: $space-l;
  }
}
</style>
