var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test"},[_c('button-redirect',{attrs:{"text":"Назад к обучению"},on:{"click":function($event){return _vm.$router.push({ name: 'Study' })}}}),_c('h4',[_vm._v("Тест: проверка перед экзаменом")]),_c('p',[_vm._v(" Чтобы успешно пройти тестирование и получить доступ к экзамену, нужно допустить не более 2 ошибок, то есть правильно ответить минимум на 18 вопросов из 20. Время выполнения не ограниченно. ")]),(_vm.test && _vm.test.status !== 'awaited')?_c('div',{staticClass:"test__result"},[_c('p',[_vm._v(" Результат "),_c('span',[_vm._v(" верно "+_vm._s(_vm.test.fields.filter((question) => question.is_correct).length)+" из "+_vm._s(_vm.test.fields.length)+" ")])])]):_vm._e(),_c('div',{staticClass:"test__numbers"},_vm._l((_vm.test.fields),function(number,index){return _c('button',{key:index,class:{
        number_active: index === _vm.select_number && _vm.test.status === 'awaited',
        number_passed: number.answers.length && _vm.test.status === 'awaited',
        number_success: number.is_correct && _vm.test.status !== 'awaited',
        number_failed: !number.is_correct && _vm.test.status !== 'awaited',
      },on:{"click":function($event){return _vm.goToPoll(index)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])}),0),(_vm.test.status === 'not_passed')?_c('div',{staticClass:"test__replay"},[_c('main-button',{attrs:{"title":"Пройти еще раз","color":"dark","padding":"14px 24px"},on:{"click":_vm.replayTest}})],1):_vm._e(),(_vm.test)?[(_vm.test.status === 'awaited')?_c('div',{staticClass:"test__question"},[_c('test-item',{attrs:{"test-status":_vm.test.status,"poll":_vm.test.fields[_vm.select_number],"select-number":_vm.select_number,"fields":_vm.test.fields},on:{"nextQuestion":_vm.updateTest,"finishTest":_vm.finishTest}})],1):_c('div',{staticClass:"test__results"},_vm._l((_vm.test.fields),function(poll,index){return _c('test-item',{key:poll.id,attrs:{"id":[`poll-${index}`],"poll":poll,"select-number":0,"fields":[]}})}),1)]:_vm._e(),(_vm.test && _vm.test.status !== 'awaited')?[_c('div',{staticClass:"test__result"},[_c('p',[_vm._v(" Результат "),_c('span',[_vm._v(" верно "+_vm._s(_vm.test.fields.filter((question) => question.is_correct).length)+" из "+_vm._s(_vm.test.fields.length)+" ")])])]),_c('div',{staticClass:"test__numbers"},_vm._l((_vm.test.fields),function(number,index){return _c('button',{key:index,class:{
          number_active: index === _vm.select_number && _vm.test.status === 'awaited',
          number_passed: number.answers.length && _vm.test.status === 'awaited',
          number_success: number.is_correct && _vm.test.status !== 'awaited',
          number_failed: !number.is_correct && _vm.test.status !== 'awaited',
        },on:{"click":function($event){return _vm.goToPoll(index)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])}),0),_c('div',{staticClass:"test__replay"},[(_vm.test.status === 'not_passed')?_c('main-button',{attrs:{"title":"Пройти еще раз","color":"dark","padding":"14px 24px"},on:{"click":_vm.replayTest}}):_vm._e(),_c('main-button',{attrs:{"title":"Вернуться к обучению","color":"outlined","padding":"14px 24px"},on:{"click":function($event){return _vm.$router.push({ name: 'Study' })}}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }